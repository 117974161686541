// API configuration
const API_URL = import.meta.env.VITE_API_URL || 'https://thelostloft.com';

// Add debugging
const logApiCall = (endpoint, options) => {
  console.log(`Making API call to: ${endpoint}`, options);
};

// Default fetch options
const defaultOptions = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Origin': window.location.origin
  },
  mode: 'cors'
};

export const API_ENDPOINTS = {
  upcomingEvents: `${API_URL}/api/upcoming-events`,
  pastEvents: `${API_URL}/api/past-events`,
  releases: `${API_URL}/api/releases`,
  artists: `${API_URL}/api/artists`,
  health: `${API_URL}/api/health`,
  debug: `${API_URL}/api/debug`,
  inquiries: `${API_URL}/api/inquiries`
};

// Add request helper with error handling
export const fetchFromApi = async (endpoint, customOptions = {}) => {
  const options = {
    ...defaultOptions,
    ...customOptions,
    headers: {
      ...defaultOptions.headers,
      ...(customOptions.headers || {})
    }
  };

  logApiCall(endpoint, options);
  
  try {
    const response = await fetch(endpoint, options);
    
    if (!response.ok && response.type !== 'opaque') {
      const errorData = await response.json().catch(() => ({}));
      console.error('API Error:', errorData);
      console.error('Response status:', response.status);
      console.error('Response headers:', Object.fromEntries([...response.headers]));
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    // Handle opaque response from no-cors mode
    if (response.type === 'opaque') {
      console.log('Received opaque response from API (no-cors mode)');
      return null;
    }
    
    return await response.json();
  } catch (error) {
    console.error(`API call failed: ${error.message}`);
    throw error;
  }
};

export default API_ENDPOINTS;
